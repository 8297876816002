import React, { useContext } from "react";
import {
  Table,
  Empty,
  Select
} from "antd";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { get } from 'Util/API';
import withStore from 'Util/withStore';
import useWindowSize from 'Util/useWindowSize';
import { GlobalContext } from 'Store/store';

const OrdersTableContent = (props) => {
  const { t } = useTranslation('orderPage');
  const size = useWindowSize();
  // const tableWidth = size[0] - 310;
  const { state: { global }, ordersTableData, setIsLoading, resetState, history } = props;
  const { data: dataSource, rowTotal } = ordersTableData;
  const [state] = useContext(GlobalContext);

  const handleTableChange = (pagination, filters, sorter) => {
    props.setOrdersTableData({
      page: pagination.current,
      limit: pagination.pageSize,
      sortBy: sorter.field,
      orderBy: sorter.order
    })
  }

  const EmptyData = ({ path, text, type, description, onClick }) => (
    <Empty
      imageStyle={{
        height: 60
      }}
      description={description}
    >
    </Empty>
  );

  const getAllOrders = async () => {
    setIsLoading(true);
    const dataCopy = { ...ordersTableData };
    dataCopy.dateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.dateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();
    delete dataCopy.limit;
    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });
    const orderResponse = await get(`order?limit=${rowTotal}&` + params.join(""));
    setIsLoading(false);
    if (orderResponse.status === 200) {
      return orderResponse.data.data;
    } else {
      message.error(orderResponse);
      return [];
    };
  }

  return (
    <div>
      <Table
        rowSelection={props.rowSelection}
        dataSource={dataSource}
        columns={props.columns}
        total={props.pageTotal}
        pagination={{
          current: ordersTableData.page,
          total: props.ordersTableData.rowTotal,
          defaultPageSize: 10,
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
          showTotal: (total, range) => `${range[1]} of ${total} ${t('content.tableContent.items')}`
        }}
        onChange={handleTableChange}
        loading={false}
        scroll={{ x: 1200 }}
        style={{ maxHeight: "60%" }}
        locale={{
          emptyText: (
            <EmptyData
              text={t('content.header.newOrder')}
            />
          )
        }}
      />
    </div>
  )
}

export default withStore(OrdersTableContent);
