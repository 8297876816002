import { Button } from "antd";
import React from "react";
import { API_ROOT } from 'Util/API';
import moment from "moment";

const ordersTableColumns = (props) => {
  const { state: { global } } = props;
  // const { customerDetails } = global;

  return [
    {
      title: 'Manifest ID',
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (id, data) => {
        return (
          <div>
            #{id}
          </div>
        );
      }
    },
    {
      title: 'Courier',
      dataIndex: "courier",
      key: "courier",
      width: 50,
      render: (text, record) => {
        return (
          <div>
            {record.service?.name || 'Unknown'}
          </div>
        );
      }
    },
    {
      title: 'Sender',
      dataIndex: "sender",
      key: "sender",
      width: 150,
      render: (text, record) => {
        const address = [];
        if (record.sender.unitNo) address.push(record.sender.unitNo);
        if (record.sender.address1) address.push(record.sender.address1);
        if (record.sender.address2) address.push(record.sender.address2);
        if (record.sender.city) address.push(record.sender.city);
        if (record.sender.state) address.push(record.sender.state);
        if (record.sender.postcode) address.push(record.sender.postcode);
        if (record.sender.country) address.push(record.sender.country);
        return (
          <div>
            <p>{record.sender?.name}</p>
            <p>{address.join(' ')}</p>
          </div>
        );
      }
    },
    {
      title: 'Created Date',
      dataIndex: "createdAt",
      key: "createdAt",
      width: 50,
      render: (text, record) => {
        return (
          <div>

            {moment(record.createdAt).format("Do MMM YYYY")}<br />
            <small>
              {moment(record.createdAt).format("h:mm a")}
            </small>
          </div>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: "action",
      key: "action",
      width: 30,
      render: (text, record) => {
        return (
          <div>
            <p>
              {record.status !== 9 ? <Button
                type="primary"
                target="_blank"
                onClick={async () => {
                  window.open(`${API_ROOT}manifest/${record.id}/print`);
                }}
              >
                Print
              </Button> : ''}
            </p>
            <p>
              {record.status === 0 ? <Button
                target="_blank"
                onClick={async () => alert('Cancelled')}
              >
                Cancel
              </Button> : ''}
            </p>
          </div>
        );
      }
    },
  ]
};

export default ordersTableColumns;
