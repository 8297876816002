import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

// import "App.css";
import { API_ROOT } from 'Util/API';
import useLocalStorage from 'Util/useLocalStorage';
import { GlobalContext } from "Store/store";

const OrdersHeader = (props) => {
  const { ordersTableData } = props;
  const [exportLink, setExportLink] = useState(null);
  const { dateRangeId, dateFrom, dateTo } = ordersTableData;
  const [state] = useContext(GlobalContext);

  const t = {};
  t.common = useTranslation('orderPage').t;
  t.pageWrapper = useTranslation('pageWrapper').t;

  useEffect(() => {
    getExportLink()
  }, [ordersTableData])

  const getExportLink = async () => {
    const dataCopy = { ...ordersTableData };
    dataCopy.dateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.dateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.limit = dataCopy.rowTotal;
    dataCopy.jwt = useLocalStorage.getItem("accessToken");
    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });
    setExportLink(`${API_ROOT}order/export?` + params.join(""))
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <p className="heading1" style={{ margin: 0, float: "left" }}>
          {t.pageWrapper('sidebarMenus.manifest')}
        </p>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        className="navigation-table"
      >
      </Col>
    </Row>
  )
}

export default OrdersHeader;
